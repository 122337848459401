import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { SamlSsoClientListComponent } from 'modules/integrations/components/saml-sso-client-list.component';
import { integrationsRoutingModule } from 'modules/integrations/integrations-routing.module';
import { SamlSsoViewComponent } from 'modules/integrations/components/sso-view.component';
import { LmsCoreModule } from 'core/core.module';
import { FormsModule } from '@angular/forms';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { SamlService } from 'modules/integrations/services/saml.service';
import { OpenIdConnectErrorComponent } from 'modules/integrations/components/open-id-connect-error.component';
import {
  OpenIdConnectLinkAccountComponent
} from 'modules/integrations/components/open-id-connect-link-account.component';
import { OpenIdConnectService } from 'modules/integrations/services/open-id-connect.service';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    UIRouterUpgradeModule.forChild({ states: integrationsRoutingModule }),
    LmsCoreModule,
    LmsComponentsModule,
    FormsModule
  ],
  declarations: [
    SamlSsoClientListComponent,
    SamlSsoViewComponent,
    OpenIdConnectErrorComponent,
    OpenIdConnectLinkAccountComponent
  ],
  providers: [
    SamlService,
    OpenIdConnectService
  ],
  exports: [
  ]
})
export class IntegrationsModule {}
