import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { ElmsUtils } from 'core/utils';
import { Observable, Subject, tap } from 'rxjs';
import {
  CACHE_NAME_TOKEN,
  CACHE_TTL_TOKEN,
  FLUSH_CACHE_TOKEN,
  TTL
} from 'core/http-interceptors/http-caching-interceptor';
import { HttpRequestParams } from 'core/services';
import { IUserSubscription } from '../models/user-network-activity.model';


enum EndpointPaths {
  GET = '/a/:activityOrigin/user_network/users/:userId/followers/:followerUserId/',
  POST = '/a/:activityOrigin/user_network/users/:userId/followers/:followerUserId/',
  DELETE = '/a/:activityOrigin/user_network/users/:userId/followers/:followerUserId/',
  GET_FOLLOWERS = '/a/user/user_network/users/me/followed_users/',
  GET_SUGGESTED_USERS = '/a/user/user_network/users/me/suggested-users/'
}

export interface ISubscriptionResponse {
  count: number;
  items: IUserSubscription[];
  error?: string;
}

export interface ISubscriptionEvent{
  deleted?: boolean;
}

@Injectable()
export class NetworkSubscriptionService {
  readonly FOLLOWERS_CACHE_KEY = 'FOLLOWERS_CACHE_KEY';
  readonly SUGGESTED_CACHE_KEY = 'SUGGESTED_CACHE_KEY';

  private subscriptionChanged$ = new Subject<ISubscriptionEvent>();
  constructor(
    private http: HttpClient,
  ) {  }

  get subscriptionChanged(): Observable<ISubscriptionEvent> {
    return this.subscriptionChanged$.asObservable();
  }

  createSubscription(userId: number, followerUserId: number, activityOrigin: string): Observable<any> {
    return this.http.post(
      ElmsUtils.formatUrl(EndpointPaths.POST, { userId, followerUserId, activityOrigin }),
      {}, {
        context: new HttpContext()
          .set(FLUSH_CACHE_TOKEN,  [this.FOLLOWERS_CACHE_KEY, this.SUGGESTED_CACHE_KEY])
      }
    ).pipe(tap(() => this.subscriptionChanged$.next({ deleted: false })));
  }

  removeSubscription(userId: number, followerUserId: number, activityOrigin: string): Observable<null> {
    return this.http.delete<null>(
      ElmsUtils.formatUrl(EndpointPaths.DELETE, { userId, followerUserId, activityOrigin }), {
        context: new HttpContext()
          .set(FLUSH_CACHE_TOKEN, [this.FOLLOWERS_CACHE_KEY, this.SUGGESTED_CACHE_KEY])
      }).pipe(tap(() => this.subscriptionChanged$.next({ deleted: true })));
  }

  getFollowedUsers(params: HttpRequestParams): Observable<ISubscriptionResponse> {
    return this.http.get<ISubscriptionResponse>(
      EndpointPaths.GET_FOLLOWERS, {
        params: params,
        context: new HttpContext()
          .set(CACHE_NAME_TOKEN, this.FOLLOWERS_CACHE_KEY)
          .set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE)
      }
    );
  }

  getSuggestedUsers(params: HttpRequestParams): Observable<ISubscriptionResponse> {
    return this.http.get<ISubscriptionResponse>(EndpointPaths.GET_SUGGESTED_USERS,  {
      params: params
    });
  }
}

