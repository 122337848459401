import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CurrentUserService } from 'core/authorization';
import { ActivityOrigin, IUserSubscription } from '../models/user-network-activity.model';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';
import { NetworkSubscriptionService } from '../services/network-subscription.service';
import { HttpRequestParams } from 'core/services';
import { finalize, Unsubscribable } from 'rxjs';


@Component({
  selector: 'network-subscriptions',
  templateUrl: 'network-subscriptions.component.html'
})
export class NetworkSubscriptionsComponent implements OnInit, OnDestroy {
  @Input() userId: number;
  @Input() activityOrigin: ActivityOrigin;
  @Input() mode: 'suggested' | 'followed' = 'suggested';
  @Input() trackingName: string;
  @Input() count: number;
  @Input() users: IUserSubscription[] = []; // TODO @Output?
  @Input() usersCount: number;
  @Input() narrowView: boolean;

  startDoc = 0;
  loading = false;
  subscribingUser?: number;
  completeness: number;

  subscriptionChangedSubscriber?:Unsubscribable;
  constructor(
    private currentUserService: CurrentUserService,
    private notificationService: NotificationService,
    private networkSubscriptionService: NetworkSubscriptionService,
  ) { }


  ngOnInit() {
    if (!this.usersCount) {
      this.loadData(false);
    }

    this.subscriptionChangedSubscriber = this.networkSubscriptionService
      .subscriptionChanged
      .subscribe(() => {
        this.loadData(this.mode === 'suggested');
      });
  }

  ngOnDestroy() {
    this.subscriptionChangedSubscriber?.unsubscribe();
  }

  loadData(append?:boolean) {
    const searchParams:HttpRequestParams = { count: 3 };

    if (this.count) {
      searchParams.count = this.count;
    }

    if (this.mode !== 'suggested') {
      searchParams.startDoc = this.startDoc;
    }

    const observable = this.mode === 'suggested' ?
      this.networkSubscriptionService.getSuggestedUsers(searchParams) :
      this.networkSubscriptionService.getFollowedUsers(searchParams);

    observable.subscribe((data) => {
      if (append) {
        this.users = this.users.filter( (user) => {
          return (this.mode === 'suggested') === user.unfollowed;
        });

        for (let i = 0; i < data.items.length; i++) {
          if (this.users.length === searchParams.count) {
            break;
          }

          if (this.users.filter((u) => u.id === data.items[i].id).length === 0) {
            this.users.push(data.items[i]);
          }
        }
      } else {
        this.users = data.items;
      }

      this.usersCount = data.count;
    });
  }

  showNext() {
    if (this.mode !== 'suggested') {
      this.startDoc += this.count;
    }

    if (this.startDoc >= this.usersCount) {
      this.startDoc = 0;
    }

    this.loadData();
  }

  changeSubscription(user: IUserSubscription) {
    const followerUserId = this.currentUserService.get().id;
    const observable = user.unfollowed ?
      this.networkSubscriptionService.createSubscription(user.id, followerUserId, this.activityOrigin) :
      this.networkSubscriptionService.removeSubscription(user.id, followerUserId, this.activityOrigin);

    this.subscribingUser = user.id;
    observable.pipe(finalize(() => {
      this.subscribingUser = undefined;
    })).subscribe(() => {
      this.notificationService.info(
        `You have been ${user.unfollowed ? 'subscribing' : 'unsubscribing'} user successfully`,
        2e3);

      if (this.users.length === 1 && user.unfollowed) {
        this.startDoc = this.startDoc > this.count ? this.startDoc - this.count : 0;
      }

      this.loadData();
    });
  }
}
