import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { GroupService } from 'modules/group/services/group.service';
import { GroupCategoryService } from 'modules/group/services/group-category.service';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    LmsComponentsModule
  ],
  declarations: [
  ],
  providers: [
    GroupService,
    GroupCategoryService,
  ]
})
export class GroupModule {}
