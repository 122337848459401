<div
  [ngClass]="{
    'has-error':
      (passwordControl.invalid || (user && user.oldPassword === value)) &&
      (submitted || passwordControl.dirty)
  }" *ngIf="rules"
>
  <input
    type="password"
    class="form-control"
    id="password"
    name="password"
    placeholder="Password"
    #passwordControl="ngModel"
    [(ngModel)]="value"
    (ngModelChange)="onPasswordChange($event)"
    autocomplete="new-password"
    maxlength="100"
    required
  />

  <p
    class="help-block"
    *ngIf="passwordControl.invalid && (passwordControl.dirty || submitted)">
    <span *ngIf="passwordControl.errors?.required">Password is required.</span>
    <span *ngIf="passwordControl.errors?.sameAsOld">Password is the same as old one.</span>
    <span *ngIf="passwordControl.errors?.passwordPolicy">passwordPolicy</span>
  </p>
</div>

<password-policies
  [user]="user"
  class="display-block sm-margin-top"
  [showOnlyFailed]="showOnlyFailedPolicies"
  [rules]="rules"
  [newPasswordRules]="true"
  *ngIf="!showOnlyFailedPolicies || passwordDirty"
  [validatedRules]="validatedRules"
  (rulesChange)="onRulesChange($event)"
></password-policies>
