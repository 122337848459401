import { NgModule } from '@angular/core';
import { NavigationTilesComponent } from 'modules/navigation/components/navigation-tiles.component';
import { CommonModule } from '@angular/common';
import { NavigationMenuComponent } from 'modules/navigation/components/navigation-menu.component';
import { UIRouterModule } from '@uirouter/angular';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from 'modules/navigation/services/menu.service';


@NgModule({
  imports: [
    CommonModule,
    UIRouterModule,
    NgbDropdownToggle,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
  ],
  declarations: [
    NavigationTilesComponent,
    NavigationMenuComponent,
  ],
  exports: [
    NavigationTilesComponent,
    NavigationMenuComponent,
  ],
  providers: [
    MenuService
  ]
})
export class NavigationModule {}
