import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'email-verification-important-modal',
  templateUrl: './email-verification-important-modal.component.html'
})
export class EmailVerificationImportantModalComponent {
  constructor(private activeModal: NgbActiveModal) {}

  cancel(): void {
    this.activeModal.dismiss();
  }

  submit(): void {
    this.activeModal.close();
  }
}

