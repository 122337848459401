<div>
  <div *ngIf="status === 'enter_email'">
    <form #userEmailForm="ngForm" (ngSubmit)="submit()" novalidate>
      <h3 *ngIf="!accountLocked">Forgot your {{ signInWording.username }} or password?</h3>
      <h3 *ngIf="accountLocked">Your account is locked {{ lockedDescription }}</h3>

      <div class="form-group" [ngClass]="{'has-error': userEmailForm.submitted && userEmail.errors?.required}">
        <label class="mb-10 text-normal" for="userEmail">
          Enter your <span class="nowrap">{{ emailText }} <span class="asterisk display-inline">*</span></span>
        </label>
        <input type="text" id="userEmail" class="form-control required"
               name="userEmail" [(ngModel)]="model.userEmail"
               #userEmail="ngModel" autoFocus
               placeholder="Enter {{emailText}}" required autofocus/>
        <span class="text-danger" *ngIf="userEmailForm.submitted && userEmail.errors?.required">
          Required
        </span>
      </div>

      <div class="form-group" *ngIf="!contactsPageExists && helpPageExists">
        Click <a [href]="helpLink" title="Go to Help page">here</a> for FAQ or to contact support
      </div>
      <div class="form-group" *ngIf="!contactsPageExists && !helpPageExists && supportPageExists">
        Click <a href="#" title="Go to Help page" [href]="supportLink">here</a> for FAQ or to contact support
      </div>
      <div class="form-group" *ngIf="contactsPageExists">
        If you don't remember your login name or email please contact <a [href]="contactsLink" title="Go to Support page">support</a>
      </div>

      <div class="row">
        <div class="col-sm-12 text-right">
          <button type="button" class="btn btn-default btn-outline sm-margin-right"
                  title="Go back" (click)="goBack()">
            Cancel
          </button>

          <button type="submit" class="btn btn-primary"
                  [disabled]="userEmailForm.invalid || !!processingSubscriber" [ladda]="!!processingSubscriber">
            Recover password
          </button>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="status === 'email_sent'">
    <h3>Check your email</h3>
    <p>
      If the {{ emailText }} you entered is associated with a user account in our records,
      you will receive an e-mail from us with instructions for resetting your password.
    </p>
    <p>
      If you don't receive this e-mail after 15 minutes, please check your junk mail folder<span
      *ngIf="!contactsPageExists && helpPageExists"> or visit our
        <a [href]="helpLink" title="Go to Help page">Help pages</a>
        to contact Support Team for further assistance</span><span *ngIf="contactsPageExists"> other email addresses,
      or contact <a [href]="contactsLink" title="Support">support</a> for further assistance</span>.
    </p>
    <div class="row">
      <div class="col-sm-12 text-right">
        <button class="btn btn-primary" (click)="goBack()">
          OK
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="status === 'limit_exceeded'">
    <h3>Daily limit</h3>
    <p>You've exceeded the number of allowed password change requests per day.</p>
    <p *ngIf="contactsPageExists">Please try again in 24 hours or contact <a [href]="contactsLink" title="Support">support</a> for further assistance.</p>
    <p *ngIf="!contactsPageExists">Please try again in 24 hours
      <span *ngIf="helpPageExists">or visit our
        <a [href]="helpLink" title="Go to Help page">Help pages</a> to contact Support Team
        for further assistance</span>.
    </p>

    <div class="row">
      <div class="col-sm-12 text-right">
        <button class="btn btn-primary" (click)="goBack()">
          OK
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="status === 'error'">
    <h3>Error</h3>
    <p>An error occurred during the request.</p>
    <p>Please try again
      <span *ngIf="helpPageExists">or visit our <a [href]="helpLink" title="Go to Help page">Help pages</a>
        to contact Support Team for further assistance</span>
      <span *ngIf="!helpPageExists && supportPageExists">or visit our
        <a [href]="supportLink" title="Go to Support page">Support pages</a> to contact Support Team
        for further assistance</span>.
    </p>
    <div class="row">
      <div class="col-sm-12 text-right">
        <button class="btn btn-primary" (click)="goBack()">
          OK
        </button>
      </div>
    </div>
  </div>
</div>
