<back-button></back-button>

<div *ngIf="loading">
  <div class="load-spinner"></div>
</div>
<h1 [innerHtml]="getHelpName()"></h1>
<div *ngIf="!loading && helpText">
  <hr>
  <div [innerHtml]="getHelpText()" class="md-view" anchorScroll></div>
</div>
