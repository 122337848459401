import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { LearningSeriesService } from './services/learning-series.service';
import { SectionRequirementTypeComponent } from 'modules/learning-series/components/section-requirement-type.component';
import {
  LearningSeriesUserStatusComponent
} from 'modules/learning-series/components/learning-series-user-status.component';
import { UserLearningSeriesService } from './services/user-learning-series.service';
import { LearningSeriesStructureComponent } from './components/learning-series-structure.component';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { CourseCommonModule } from 'modules/course/common/course-common.module';
import { CourseRegistrationCommonModule } from 'modules/course-registrations/course-registration-common.module';
import { LmsDatesModule } from 'components/dates/dates.module';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';


@NgModule({
  imports: [
    CommonModule,
    CourseCommonModule,
    CourseRegistrationCommonModule,
    LmsComponentsModule,
    LmsDatesModule,
    HttpClientModule,
    UIRouterUpgradeModule.forChild({ }),
  ],
  declarations: [
    SectionRequirementTypeComponent,
    LearningSeriesUserStatusComponent,
    LearningSeriesStructureComponent
  ],
  providers: [
    LearningSeriesService,
    UserLearningSeriesService
  ],
  exports: [
    SectionRequirementTypeComponent,
    LearningSeriesUserStatusComponent,
    LearningSeriesStructureComponent
  ]
})
export class LearningSeriesModule {}
