import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';
import { HelpTextComponent } from './view/help-text.component';


export const helpTextRoutingModule: NgHybridStateDeclaration[] = [
  {
    name: 'main.support',
    url: '^/support',
    params: {
      id: null
    },
    component: HelpTextComponent,
    data: {
      'label': 'Help and Support Info',
      'availableFor': ['anonymous', 'regularUser', 'admin']
    }
  }
];
