<div *ngIf="!submitDisabled">
  <form method="POST" #corporateLoginForm="ngForm" id="corporateLoginForm"
        action="/a/integration/saml_sso/corporate_login/"
        (keyup.enter)="login(corporateLoginForm)" novalidate>

    <div class="form-group"
         [ngClass]="{'has-error': (corporateLoginForm.submitted || corporateLoginForm.controls.email?.dirty) &&
         (corporateLoginForm.controls.email?.errors?.required || corporateLoginForm.controls.email?.errors?.email) &&
         corporateLoginForm.controls.email?.invalid}">

      <label class="control-label" for="email">Company Email</label>
      <input class="form-control" type="email" name="email" id="email" [(ngModel)]="emailValue" #email="ngModel" required autofocus>

      <label><input type="hidden" name="target_url" [value]="targetPath"></label>

      <div class="margin-top text-danger" *ngIf="(corporateLoginForm.submitted || email.dirty) && email.invalid">
        <span *ngIf="email.errors?.required">Email is required</span>
        <span *ngIf="email.errors?.email">Please enter a valid email</span>
      </div>
    </div>

    <button type="button" class="btn btn-primary btn-lg btn-block" (click)="login(corporateLoginForm)">Sign in</button>
    <input type="submit" hidden [disabled]="submitDisabled" value="Submit"/>
  </form>
</div>

<div *ngIf="submitDisabled">
  <div class="load-spinner"></div>
</div>
