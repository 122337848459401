import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize, switchMap, tap, Unsubscribable } from 'rxjs';
import { ICourseSubjectArea } from '../../course/common/models/play-course.model';
import { CourseService } from '../../course/common/services/course.service';
import { IUser } from '../../user/models/user.model';
import { UserService } from '../../user/services/user.service';


@Component({
  selector: 'component-profile-dialog-component',
  templateUrl: './complete-profile-dialog.component.html'
})
export class ComponentProfileModalComponent implements OnInit, OnDestroy {
  currentUser: IUser;
  subjectAreas: ICourseSubjectArea[] = [];
  loading = false;

  saveSubscriber?:Unsubscribable;

  constructor(
    private activeModal: NgbActiveModal,
    @Inject('networkProfileCompletenessFields') private networkProfileCompletenessFields: string[],
    private courseService: CourseService,
    private userService: UserService,
    @Inject('user') private user: { id: number }
  ) { }

  get processing() {
    return !!this.saveSubscriber;
  }

  ngOnInit() {
    this.loadUserData();
  }

  ngOnDestroy(): void {
    this.saveSubscriber?.unsubscribe();
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

  submit(form: FormGroup): void {
    if (!form.valid) {
      return;
    }

    const userDraft =  {
      ...this.currentUser,
    };

    this.saveSubscriber = this.userService.update(userDraft)
      .pipe(
        finalize(() => {
          this.saveSubscriber.unsubscribe();
          delete this.saveSubscriber;
        }))
      .subscribe((resultUser : IUser ) => {
        this.activeModal.close(resultUser);
      });
  }

  checkField(fieldName: string) {
    return this.networkProfileCompletenessFields.includes(fieldName);
  }

  private loadUserData() {
    this.loading = true;
    this.userService.get(this.user.id)
      .pipe(
        tap((user) => {
          this.currentUser = user;
        }),
        switchMap(() => {
          return this.courseService.getCourseOptions();
        }),
        finalize(() => {
          this.loading = false;
        }))
      .subscribe((options) => {
        this.subjectAreas = options.subjectAreas;
      });
  }
}
