<div class="primary-panel" *ngIf="users?.length || mode !== 'suggested'">
    <h4  class="mt-0 mb-10" *ngIf="narrowView">{{mode === 'suggested' ? 'Who to Follow': 'Following'}}</h4>

    <div *ngIf="!loading && users && !users.length">
      <div class="alert alert-info mb-0">
        There are no users found.
      </div>
    </div>

    <fieldset [disabled]="loading" class="mb-0">
      <legend class="sr-only">Follow more users</legend>
      <div *ngFor="let user of users; let last=last" [ngClass]="{'mb-10':!last}">
        <div class="d-flex items-center gap-10">
            <div>
              <user-avatar [user]="user" [size]="42"></user-avatar>
            </div>
            <div class="flex-1 text-truncate-link">
                  <a href="#" uiSref="main.user_network" [uiParams]="{userId: user.id}" title="User Feed"
                    [id]="trackingName+'_followed_users_link'">
                    {{user.title}}
                  </a>
                  <span *ngIf="user.unfollowed && mode !== 'suggested'">(Unfollowed)</span>
            </div>
            <div>
                <button class="btn btn-outline btn-block"  type="button" (click)="changeSubscription(user)"
                        [disabled]="!!subscribingUser" [ngClass]="{'pull-right': !narrowView}" [ladda]="subscribingUser === user.id"
                        data-spinner-color="#000"
                        [id]="trackingName+'_followed_users_'+(user.unfollowed ? 'follow' :'unfollow')">
                        {{user.unfollowed ? 'Follow' :'Unfollow'}}
                 </button>
            </div>
        </div>
      </div>
    </fieldset>

    <div *ngIf="narrowView && usersCount > users.length" class="mt-5">
      <button type="button" class="btn btn-link no-padding-all" (click)="showNext()" [disabled]="loading"
              title="Suggest more users to follow"
              [id]="trackingName+'_followed_users_show_more'">
        {{mode==='suggested' ? 'Suggest more' : 'Show Next'}}
      </button>
    </div>
</div>
