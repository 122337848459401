import { Injectable } from '@angular/core';
import { GlobalConfig } from 'core/environment';
import { SecurityService } from 'core/services';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { StateService } from '@uirouter/core';
import _ from 'lodash';
import { IUser } from 'modules/user/models/user.model';
import { BackUrlService } from 'core/navigation/back-url.service.ajs-upgraded-provider';
import { IAdminMenuItem } from 'modules/navigation/models/menu.models';
import { IMainMenuItem } from 'core/models/menu.models';


@Injectable()
export class MenuService {
  adminMenu: IAdminMenuItem[];

  constructor(private globalConfig: GlobalConfig, private securityService: SecurityService,
              private currentUserService: CurrentUserService, private stateService: StateService,
              private backUrlService: BackUrlService) {
    this.currentUserService.userInfoLoaded$.subscribe(() => {
      this.adminMenu = this._buildAdminMenu();
    });
  }

  getFirstMenuState(): IMainMenuItem {
    for (let i = 0; i < this.globalConfig.menu.length; i++) {
      if (this._isMenuAvailable(this.globalConfig.menu[i]) && !this.globalConfig.menu[i].external) {
        return this.globalConfig.menu[i];
      }
    }

    if (this.securityService.isStateAvailable('prompt.login')) {
      return { stateName: 'prompt.login', name: 'login' };
    }

    return null;
  }

  getAdminMenuItems() {
    if (!this.adminMenu) {
      this.adminMenu = this._buildAdminMenu();
    }

    return this.adminMenu;
  }

  isMenuItem(stateName: string) {
    return _.some(this.globalConfig.menu, ['stateName', stateName]);
  }

  buildMenuLink(menuItem: any): string {
    const linkTemplate = _.template(menuItem.href);

    return menuItem.href
      ? linkTemplate({ basePath: this.globalConfig.base, externalDomain: this.globalConfig.externalDomain })
      : menuItem.stateName
        ? this.stateService.href(menuItem.stateName)
        : '';
  }

  getMenuItems() {
    const menu = [];

    this._setupAdminRoot();

    this.globalConfig.menu.forEach(menuItem => {
      if (this._isMenuAvailable(menuItem)) {
        menu.push({
          title: menuItem.name,
          type: menuItem.type,
          state: menuItem.stateName ? menuItem.stateName : '',
          stateParams: menuItem.stateParams,
          link: this.buildMenuLink(menuItem),
          target: menuItem.target ? menuItem.target : '_self',
          icon: menuItem.icon ? menuItem.icon : '',
          backUrl: menuItem.backUrl
        });
      }
    });

    return menu;
  }

  private _collectMenu(user: IUser, parentMenuItem?: any) {
    const menuItems = parentMenuItem ? parentMenuItem.children : _.cloneDeep(this.globalConfig.adminMenuItems);

    return _.filter(menuItems || [], (item: any) => {
      if (item.data?.environment && !_.includes(item.data.environment, this.globalConfig.environment)) {
        return false;
      }

      item.children = this._collectMenu(user, item);

      if (item.link) {
        item._link = item.link;
        Object.defineProperty(item, 'link', {
          get: () => {
            const backUrlParam = this.backUrlService.external.getBackUrlParam();

            return item.includeBackUrl
              ? `${item._link}${item._link.includes('?') ? '&' : '?'}${backUrlParam}`
              : item._link;
          }
        });
      }

      if (item.roleIds && !user.isInRole(item.roleIds)) {
        return false;
      }

      if (item.roleIdsExclusions && user.isInRole(item.roleIdsExclusions)) {
        return false;
      }

      return !(item.state && !this.securityService.isStateAvailable(item.state));
    });
  }

  private _collectAllRoles(collection: any[]) {
    return _.uniq(
      _.reduce(
        collection,
        (roles, child) => {
          if (child.state) {
            const state = this.stateService.get(child.state);

            return state?.data?.adminRoleLimits ? roles.concat(state.data.adminRoleLimits) : roles;
          }

          return roles;
        },
        []
      ).concat(
        _.reduce(
          collection,
          (roles, child) => {
            return child.roleIds ? roles.concat(child.roleIds) : roles;
          },
          []
        )
      )
    );
  }

  private _isMenuAvailable(menuItem: any) {
    if (menuItem.stateName) {
      const available = this.securityService.isStateAvailable(menuItem.stateName);

      return menuItem.data?.availableFor ? available && this.securityService.isPageAvailable(menuItem) : available;
    }

    return this.securityService.isPageAvailable(menuItem);
  }

  private _buildAdminMenu() {
    const user = this.currentUserService.get();

    if (!user.roles || !user.roles.length) {
      return [];
    }

    return this._collectMenu(user, null);
  }

  private _setupAdminRoot() {
    const adminRoot = this.stateService.get('main.admin');

    if (adminRoot) {
      if (!adminRoot.data) {
        adminRoot.data = {};
      }

      const roles = this._collectAllRoles(this.getAdminMenuItems());

      adminRoot.data.adminRoleLimits = roles.concat(adminRoot.data.adminRoleLimits || []);
    }
  }
}
