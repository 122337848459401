import { Component, Injector, Input, OnInit } from '@angular/core';
import { CurrentUserService } from 'core/authorization';
import { IUser } from '../../user/models/user.model';
import { NetworkRotatorService } from '../services/network-rotator.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentProfileModalComponent } from '../modal/complete-profile-dialog.component';
import { UrlVersionService } from 'core/services';


@Component({
  selector: 'network-profile-completeness',
  templateUrl: 'network-profile-completeness.component.html'
})
export class NetworkProfileCompletenessComponent implements OnInit {
  @Input() user: IUser;
  @Input() trackingName: string;

  completeness: number;
  constructor(
    private currentUserService: CurrentUserService,
    private networkRotatorService: NetworkRotatorService,
    private ngbModal: NgbModal,
    private injector:Injector,
    private urlVersionService: UrlVersionService,
  ) { }


  ngOnInit() {
    this.calculateCompleteness();
  }

  editProfile() {
    const modalRef = this.ngbModal.open(ComponentProfileModalComponent, {
      backdrop: 'static',
      injector: Injector.create({
        parent: this.injector,
        providers: [
          { provide: 'user', useValue: { id: this.user.id } },
        ]
      })
    });

    modalRef.result.then((user:IUser) => {
      if (!user) {
        return;
      }

      Object.assign(this.user, user);
      this.currentUserService.set(user);
      this.calculateCompleteness();
      this.urlVersionService.update();
    }, () => null);
  }

  private calculateCompleteness() {
    this.completeness = this.networkRotatorService.getNetworkProfileCompleteness(this.user);
  }
}
