<div>

  <h3>Password Assistance</h3>

  <!-- Incorrect Status -->
  <div *ngIf="status === 'incorrect'">
    <p>Password recovery link is incorrect.</p>
    <p>
      Please try again or make a new request to reset your password.<br />
      <span *ngIf="helpPageExists">To contact Support Team, visit our <a [href]="helpLink" title="Go to Help page">Help pages</a>.</span>
      <span *ngIf="supportPageExists">To contact Support Team, visit our <a [href]="supportLink" title="Go to Support page">Support pages</a>.</span>
    </p>

    <div class="row">
      <div class="col-sm-12 text-right">
        <button type="button" class="btn btn-default btn-outline sm-margin-right" (click)="redirectBack()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="next()">Next</button>
      </div>
    </div>
  </div>

  <!-- Loading Spinner -->
  <div *ngIf="!status">
    <div class="load-spinner"></div>
  </div>

  <!-- Expired Status -->
  <div *ngIf="status === 'expired'">
    <p>It has been more than 24 hours since you made this request, and it has become inactive.</p>
    <p>Please make a new request to reset your password.</p>

    <div class="row">
      <div class="col-sm-12 text-right">
        <button type="button" class="btn btn-default btn-outline sm-margin-right" (click)="redirectBack()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="next()">Next</button>
      </div>
    </div>
  </div>

  <!-- Password Changed Status -->
  <div *ngIf="status === 'changed'">
    <p>Your password has been changed successfully.</p>

    <div class="row">
      <div class="col-sm-12 text-right">
        <button type="button" class="btn btn-primary" (click)="goToLogin()">Go to {{signInWording?.SignIn || 'Sign in'}} page</button>
      </div>
    </div>
  </div>

  <!-- Correct Status: Show Form for Password Reset -->
  <form *ngIf="status === 'correct'" #passwordAssistanceForm="ngForm" (ngSubmit)="submit()" role="form" class="form-horizontal" novalidate>
    <div class="row">
      <div class="col-xs-12">
        <div class="form-group" [ngClass]="{'has-error': false}">
          <label for="password" class="col-sm-4 control-label">New password <required-field></required-field></label>
          <div class="col-sm-8">
            <password-input
              [(ngModel)]="user.password"
              name="password"
              #passwordControl="ngModel"
              [user]="user"
              [submitted]="submitted"
            ></password-input>
          </div>
        </div>

        <div class="form-group" [ngClass]="{'has-error': user.password !== passwordConfirmationValue && user.password && passwordConfirmation.dirty}">
          <label for="passwordConfirmation" class="col-sm-4 control-label">
            Confirm new <span class="nowrap">password <required-field class="display-inline-block"></required-field></span>
          </label>
          <div class="col-sm-8">
            <input #passwordConfirmation="ngModel"
                   [(ngModel)]="passwordConfirmationValue"
                   name="passwordConfirmation"
                   class="form-control required" type="password" id="passwordConfirmation"
                   autocomplete="off" placeholder="Confirm New Password" required
                   [passwordMatch]="user.password"/>
            <p class="help-block"
              *ngIf="passwordConfirmation.invalid && (passwordConfirmation.dirty || submitted)">
              <span *ngIf="passwordConfirmation.errors?.passwordMismatch">
                Passwords do not match.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Error Display -->
    <div *ngIf="error" class="alert alert-sm alert-danger">{{error}}</div>

    <fieldset [disabled]="userSecuritySubscriber" class="no-margin-bottom text-right" aria-label="Actions">
      <button type="button" class="btn btn-default btn-outline sm-margin-right" (click)="redirectBack()">Cancel</button>
      <button type="submit" class="btn btn-primary" [disabled]="userSecuritySubscriber || passwordAssistanceForm.invalid" data-spinner-color="#000">Submit</button>
    </fieldset>
  </form>
</div>
<!--<div>-->
<!--  <div ng-hide="hideForm" >-->
<!--    <div>-->
<!--      <div>-->

<!--        <h3>Password Assistance</h3>-->

<!--        <div ng-show="vm.status==='incorrect'">-->
<!--          <p>-->
<!--            Password recovery link is incorrect.</p>-->
<!--          <p>Please try again or make a new request to reset your password.<br/>-->
<!--            <span ng-if="vm.helpPageExists">To contact Support Team visit our <a ui-sref="main.help" title="Go to Help page">Help pages</a>.</span>-->
<!--            <span ng-if="vm.supportPageExists">To contact Support Team visit our <a ui-sref="main.support" title="Go to Help page">Help pages</a>.</span>-->
<!--          </p>-->

<!--          <div class="row">-->
<!--            <div class="col-sm-12 text-right">-->
<!--              <button type="button" class="btn btn-default btn-outline sm-margin-right" ng-click="vm.cancel()">Cancel</button>-->
<!--              <button type="submit" class="btn btn-primary" ng-click="vm.next()">Next</button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div ng-show="!vm.status">-->
<!--          <div class="load-spinner"></div>-->
<!--        </div>-->
<!--        <div ng-show="vm.status==='expired'">-->
<!--          <p>-->
<!--            It has been more than 24 hours since you made this request and it has become inactive.-->
<!--          </p>-->
<!--          <p>-->
<!--            Please make a new request to reset your password.-->
<!--          </p>-->

<!--          <div class="row">-->
<!--            <div class="col-sm-12 text-right">-->
<!--              <button type="button" class="btn btn-default btn-outline sm-margin-right" ng-click="vm.cancel()">Cancel</button>-->
<!--              <button type="submit" class="btn btn-primary" ng-click="vm.next()">Next</button>-->
<!--            </div>-->
<!--          </div>-->


<!--        </div>-->
<!--        <div ng-show="vm.status==='changed'">-->
<!--          <p>-->
<!--            Your password has been changed successfully.-->
<!--          </p>-->

<!--          <div class="row">-->
<!--            <div class="col-sm-12 text-right">-->
<!--              <button type="submit" class="btn btn-primary" ng-click="vm.goToLogin()">Go to {{vm.signInWording.SignIn || 'Sign in' }} page</button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <form ng-submit="vm.submit()" name="passwordAssistanceForm" ng-if="vm.status==='correct'" role="form" class="form-horizontal" novalidate>-->
<!--          <div class="row">-->
<!--            <div class="col-xs-12">-->
<!--              <div class="form-group"-->
<!--                   ng-form name="passwordOuterForm"-->
<!--                   ng-class="{'has-error': passwordOuterForm.$invalid && passwordOuterForm.$dirty}">-->
<!--                <label for="password" class="col-sm-4 control-label">New password <required-field></required-field></label>-->
<!--                <div class="col-sm-8">-->
<!--                  <password-input user="vm.user" submitted="vm.submitted"></password-input>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="form-group"-->
<!--                   ng-class="{'has-error':passwordAssistanceForm.passwordConfirmation.$invalid && vm.user.password && passwordAssistanceForm.passwordConfirmation.$dirty}">-->
<!--                <label for="passwordConfirmation" class="col-sm-4 control-label">Confirm new <span class="nowrap">password <required-field class="display-inline-block"></required-field></span></label>-->

<!--                <div class="col-sm-8">-->
<!--                  <input class="form-control required" type="password" id="passwordConfirmation" name="passwordConfirmation"-->
<!--                         autocomplete="off" placeholder="Confirm New Password" ng-maxlength="100" required ng-model="passwordConfirmation"-->
<!--                         validate-equality="vm.user.password"/>-->
<!--                  <p class="help-block" ng-show="passwordAssistanceForm.passwordConfirmation.$invalid && vm.user.password">-->
<!--                    <span class="pull-left" ng-show="passwordAssistanceForm.passwordConfirmation.$error.equal">Password fields do not match.</span>-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->

<!--            </div>-->
<!--          </div>-->
<!--          <div class="alert alert-sm alert-danger" ng-if="vm.error">{{vm.error}}</div>-->

<!--          <fieldset data-ng-disabled="vm.userSecurityPromise" class="no-margin-bottom text-right">-->
<!--            <button type="button" class="btn btn-default btn-outline sm-margin-right" ng-click="vm.cancel()">Cancel</button>-->
<!--            <button type="submit" class="btn btn-primary" data-ng-disabled="vm.userSecurityPromise || passwordAssistanceForm.$invalid || passwordAssistanceForm.$pending" ladda="vm.loading"-->
<!--                    data-spinner-color="#000">Submit</button>-->
<!--          </fieldset>-->
<!--        </form>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
