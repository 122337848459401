import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Observable, of, Subject, takeUntil, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ElmsUtils } from 'core/utils';
import { IUser } from 'modules/user/models/user.model';
import { ICustomerRule, IValidationResult } from 'modules/password/models/password-policies.model';
import { CACHE_NAME_TOKEN, CACHE_TTL_TOKEN } from 'core/http-interceptors/http-caching-interceptor';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';


export enum PasswordPoliciesEndpoints {
  getRules = '/a/password-rules/customer/',
  checkRules = '/a/user/password/?action=check_rules',
  checkRulesForUser = '/a/user/:userId/password/?action=check_rules'
}

@Injectable()
export class PasswordPoliciesService {
  // Subject to emit when the request should be canceled
  readonly PASSWORD_RULES_CACHE = 'passwordRules';
  private cancelRequest$ = new Subject<void>();

  constructor(private http: HttpClient) {}

  // Method to get customer rules
  getCustomerRules(cache: boolean): Observable<ICustomerRule[]> {
    let requestParamters = {};

    if (cache) {
      requestParamters = {
        context: new HttpContext()
          .set(CACHE_NAME_TOKEN, this.PASSWORD_RULES_CACHE)
          .set(CACHE_TTL_TOKEN, 3600)
      };
    }

    return this.http.get<ICustomerRule[]>(PasswordPoliciesEndpoints.getRules, requestParamters).pipe(
      map(response => {
        return response.filter(passwordRule => passwordRule.rule.active && passwordRule.rule.visible);
      }),
      catchError(() => of([])) // Return an empty array in case of error
    );
  }

  // Pre-validate password rules
  preValidatePasswordRules(rules, password: string, user: Partial<IUser>): IValidationResult {
    const validationResult: IValidationResult = {
      failedRules: [],
      completedRules: []
    };

    rules.forEach(passwordRule => {
      if (passwordRule.rule.coversNewPasswords && passwordRule.rule.active && passwordRule.rule.visible) {
        // Regex matching rules
        const regexRules = [
          'EnglishSymbolsPasswordRule',
          'LowercaseSymbolsPasswordRule',
          'NonAlphanumericSymbolsPasswordRule',
          'NumberSymbolsPasswordRule',
          'UppercaseSymbolsPasswordRule'
        ];

        if (regexRules.includes(passwordRule.rule.internalName)) {
          if (!new RegExp(passwordRule.rule.ruleData).test(password)) {
            validationResult.failedRules.push(passwordRule.rule.internalName);
          } else {
            validationResult.completedRules.push(passwordRule.rule.internalName);
          }
        }

        // Min length rule
        if (passwordRule.rule.internalName === 'MinLengthPasswordsRule' && passwordRule.rule.ruleData) {
          if (password.length < parseInt(passwordRule.rule.ruleData, 10)) {
            validationResult.failedRules.push(passwordRule.rule.internalName);
          } else {
            validationResult.completedRules.push(passwordRule.rule.internalName);
          }
        }

        // Forbid containing user info
        if (passwordRule.rule.internalName === 'ForbidContainUserInfoPasswordRule') {
          const fields = [user.loginName, user.firstName, user.lastName, user.email].filter(Boolean);
          let error = false;
          const passwordToCheck = password.toLowerCase();

          fields.forEach(field => {
            if (!error && field) {
              const loweredField = field.toLowerCase();

              if (loweredField.includes(passwordToCheck) || passwordToCheck.includes(loweredField)) {
                error = true;
              }
            }
          });

          if (error) {
            validationResult.failedRules.push(passwordRule.rule.internalName);
          } else {
            validationResult.completedRules.push(passwordRule.rule.internalName);
          }
        }
      }
    });

    return validationResult;
  }

  // Check password rules (sends a request to the backend for validation)
  checkPasswordRules(password: string, user: Partial<IUser>, throwHttpErrors = false): Observable<IValidationResult> {
    const context = new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors);

    // Cancel any pending request by emitting from the cancelRequest$ Subject
    this.cancelRequest$.next();

    let url: string = PasswordPoliciesEndpoints.checkRules;

    if (user.id) {
      url = ElmsUtils.formatUrl(PasswordPoliciesEndpoints.checkRulesForUser, { userId: user.id });
    }

    const body = {
      password: password,
      oldPassword: user.oldPassword,
      newUser: !user.id
    };

    return this.http.put<IValidationResult>(url, body, { context }).
      pipe(
        takeUntil(this.cancelRequest$),
        catchError(error => {
          console.error('Error checking password rules:', error);

          return throwError(() => error); // Throw the error instead of returning it
        })
      );
  }
}
