import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ElmsUtils } from 'core/utils';
import { IUserNetworkEntity } from 'modules/network/models/user-network-activity.model';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { tap } from 'rxjs/operators';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';


enum NetworkCommentsPaths {
  GET = '/a/:activityOrigin/user_network/comments/:commentId/',
  POST = '/a/:activityOrigin/user_network/comments/',
  PUT = '/a/:activityOrigin/user_network/comments/:commentId/',
  DELETE = '/a/:activityOrigin/user_network/comments/:commentId/',
  LIKE = '/a/:activityOrigin/user_network/comments/:commentId/likes/',
  UNLIKE = '/a/:activityOrigin/user_network/comments/:commentId/likes/'
}


@Injectable()
export class UserNetworkCommentsService {
  constructor(
    private http: HttpClient,
    private currentUser: CurrentUserService) {
  }

  public update(payload: IUserNetworkEntity, throwHttpErrors = false): Observable<IUserNetworkEntity> {
    return this.http.put<IUserNetworkEntity>(
      ElmsUtils.formatUrl(NetworkCommentsPaths.PUT, {
        commentId: payload.id,
        activityOrigin: payload.activityOrigin
      }),
      payload,
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    ).pipe(tap(this.initDates));
  }

  public save(payload: IUserNetworkEntity): Observable<IUserNetworkEntity> {
    return this.http.post<IUserNetworkEntity>(
      ElmsUtils.formatUrl(NetworkCommentsPaths.POST, { activityOrigin: payload.activityOrigin }),
      payload
    ).pipe(tap(this.initDates));
  }

  public delete(payload: IUserNetworkEntity): Observable<null> {
    return this.http.delete<null>(
      ElmsUtils.formatUrl(NetworkCommentsPaths.DELETE,
        {
          commentId: payload.id,
          activityOrigin: payload.activityOrigin
        })
    );
  }

  public like(activity: IUserNetworkEntity, throwHttpErrors = false): Observable<null> {
    return this.http.post<null>(
      ElmsUtils.formatUrl(
        NetworkCommentsPaths.LIKE, { commentId: activity.id, activityOrigin: activity.activityOrigin }
      ),
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    );
  }

  public unlike(activity: IUserNetworkEntity, throwHttpErrors = false): Observable<null> {
    return this.http.delete<null>(
      ElmsUtils.formatUrl(
        NetworkCommentsPaths.UNLIKE, { commentId: activity.id, activityOrigin: activity.activityOrigin }
      ),
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    );
  }

  public newComment(attrs?: Partial<IUserNetworkEntity>): IUserNetworkEntity {
    const user = this.currentUser.get();
    const currentDate = new Date();

    return Object.assign({}, {
      user: {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName
      },
      text: '',
      likes: { count: 0, liked: false },
      date: currentDate,
      updated: currentDate
    },
    attrs || {}) as IUserNetworkEntity;
  }

  private initDates(activity: IUserNetworkEntity) {
    activity.date = new Date(activity.date);
    activity.updated = new Date(activity.updated);

    return activity;
  }
}
