import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IInstructor } from 'modules/instructors/models/instructor.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { InstructorProfileModalComponent } from 'modules/instructors/view/modal/instructor-profile-modal.component';
import { ElmsUtils } from 'core/utils';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';


export enum InstructorsRequestPaths {
  GET = '/a/instructors/:instructorId',
  PUT = '/a/instructors/:instructorId/',
  POST = '/a/instructors/',
}

@Injectable()
export class InstructorsService {
  private defaultProps: Partial<IInstructor> = {
    active: true
  };

  constructor(private http: HttpClient, private ngbModalService: NgbModal) {
  }

  public get(instructorId: number): Observable<IInstructor> {
    return this.http.get<IInstructor>(
      ElmsUtils.formatUrl(InstructorsRequestPaths.GET, { instructorId })
    );
  }

  public post(payload: Partial<IInstructor>): Observable<IInstructor> {
    return this.http.post<IInstructor>(InstructorsRequestPaths.POST, payload);
  }

  public update(instructorId: number, payload: Partial<IInstructor>): Observable<IInstructor> {
    return this.http.put<IInstructor>(
      ElmsUtils.formatUrl(InstructorsRequestPaths.PUT, { instructorId }),
      payload
    );
  }

  public delete(instructorId: number, throwHttpErrors = false): Observable<IInstructor> {
    return this.http.delete<IInstructor>(
      ElmsUtils.formatUrl(InstructorsRequestPaths.PUT, { instructorId }),
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) });
  }


  public newInstructor(instructorAttr?: Partial<IInstructor>): Partial<IInstructor> {
    return {
      ...this.defaultProps,
      ...(instructorAttr || {})
    };
  }


  public showInformation(instructor: IInstructor): Promise<null> {
    const modalReference: NgbModalRef = this.ngbModalService.open(InstructorProfileModalComponent, {
      backdrop: 'static',
      animation: true,
    });

    (<InstructorProfileModalComponent>modalReference.componentInstance).instructor = instructor;

    return modalReference.result;
  }
}
