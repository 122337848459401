<ng-template #separator>
  <hr aria-hidden="true" class="tw-flex-1 tw-border tw-border-gray-4 tw-m-0" />
</ng-template>

<div class="tw-flex tw-flex-col tw-gap-4">
  <div
    class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-4"
  >
    <button
      type="button"
      class="tw-bg-brand-2 tw-text-brand-9 tw-px-4 tw-py-3 tw-border tw-border-brand-7 tw-rounded-lg hover:tw-border-brand-8 hover:tw-bg-brand-3 focus-visible:tw-outline-brand-7 focus:tw-outline-none"
      *ngFor="let item of shownItems"
      (click)="called(item)"
    >
      {{ item.name }}
    </button>
  </div>
  <div
    class="tw-flex tw-items-center tw-gap-4"
    *ngIf="couldDecrease() || couldExpand()"
  >
    <ng-container *ngTemplateOutlet="separator"></ng-container>
    <button
      type="button"
      class="twc-btn twc-btn-link"
      (click)="toggleExpand($event)"
      [innerText]="couldExpand() ? 'Show more' : 'Show less'"
    ></button>
    <ng-container *ngTemplateOutlet="separator"></ng-container>
  </div>
</div>
