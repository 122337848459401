import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { HelpTextService } from 'modules/help-texts/services/help-text.service';
import { helpTextRoutingModule } from 'modules/help-texts/help-text-routing.module';
import { HelpTextComponent } from 'modules/help-texts/view/help-text.component';
import { AnchorScrollDirective } from 'modules/help-texts/view/anchor-scroll.directive';
import { LmsCoreModule } from 'core/core.module';
import { GlobalConfig } from 'core/environment';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    UIRouterUpgradeModule.forChild({ states: helpTextRoutingModule }),
    LmsComponentsModule,
    LmsCoreModule
  ],
  declarations: [
    HelpTextComponent,
    AnchorScrollDirective
  ],
  providers: [
    HttpClient,
    CookieService,
    HelpTextService,
    AnchorScrollDirective
  ]
})
export class HelpTextModule {
  constructor(
    private globalConfig: GlobalConfig
  ) {
    this.globalConfig.settings.authorizedStates.push({
      name: 'main.support',
      params: {}
    });
  }
}
