import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IUser } from '../../user/models/user.model';
import { map, Observable } from 'rxjs';


enum EndpointPaths {
  GET = '/a/user/user_network/users/me/rotator-data/'
}

interface ILoadDataResponse {
  suggestedUsers: any;
}

export interface IAvailableViews {
  allViews: {
    profileCompleteness: null,
    suggestedSubscriptions: null
  };
  availableViews: string[];
  activeView: string;
}
@Injectable()
export class NetworkRotatorService {
  activeView: string;

  constructor(
    private http: HttpClient,
    @Inject('networkProfileCompletenessFields') private networkProfileCompletenessFields: string[]
  ) {  }

  getNetworkProfileCompleteness(user: IUser): number {
    const total = this.networkProfileCompletenessFields.length;
    let completed = 0;

    this.networkProfileCompletenessFields.forEach((item) => {
      completed += user[item] ? 1 : 0;
    });

    return (completed / total) * 100;
  }

  loadData(user: IUser, params: any):Observable<IAvailableViews> {
    return this.http.get<ILoadDataResponse>(EndpointPaths.GET, { params: { count: 3 } })
      .pipe(map((data: any) => {
        let suggestedUsers = data.suggestedUsers ?? [];

        if (params && params.suggestedUsers) {
          suggestedUsers = params.suggestedUsers;
        }

        const allViews = {
          profileCompleteness: null,
          suggestedSubscriptions: null
        };
        const availableViews = [];
        const percentage = this.getNetworkProfileCompleteness(user);

        if (percentage >= 100) {
          allViews.profileCompleteness = null;
        } else {
          allViews.profileCompleteness = true;
          availableViews.push('profileCompleteness');
        }

        if (suggestedUsers.count > 0) {
          allViews.suggestedSubscriptions = suggestedUsers;
        } else {
          allViews.suggestedSubscriptions = null;
        }

        if (!this.activeView && availableViews.length) {
          this.activeView = availableViews[0];
        } else {
          if (!params || !params.suggestedUsers) {
            if (percentage < 100) {
              this.activeView = 'profileCompleteness';
            } else {
              const index = availableViews.findIndex((item) => {
                return item === this.activeView;
              });

              this.activeView = availableViews[index === availableViews.length - 1 ? 0 : index + 1];
            }
          }
        }

        return { allViews, availableViews, activeView: this.activeView };
      }));
  }

  getDefaultView(): string {
    return this.activeView;
  }

  setActiveView(value: string): void {
    this.activeView = value;
  }
}

