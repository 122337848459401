import { Inject, Injectable, Optional } from '@angular/core';
import { catchError, Observable, of, tap, throwError } from 'rxjs';
import {
  IQueueResponse,
  IUserNetworkActivityQuery,
} from 'modules/network/services/user-network-activity.service';
import {
  IUserNetworkActivity,
  IUserNetworkEntity
} from 'modules/network/models/user-network-activity.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EditThreadModalComponent } from 'modules/network/edit/modal/edit-thread-modal.component';
import { EditThreadReplyModalComponent } from 'modules/network/edit/modal/edit-thread-reply-modal.component';


export interface ISearchEngine<T> {
  query?: (params:any) => Observable<IQueueResponse<T>>;
  like: (t: T, throwHttpErrors?: boolean) => Observable<null>;
  unlike: (t: T, throwHttpErrors?: boolean) => Observable<null>;
}

export interface INetworkServiceConfig {
  take: number
}


export class NetworkServiceResponse<T> {
  offset = 0;
  hasMoreItems = true;

  constructor(private searchEngine: ISearchEngine<T>,
              private params: IUserNetworkActivityQuery) {
  }

  performSearch(offset?: number) {
    this.offset = offset || 0;
    this.hasMoreItems = false;

    return this.searchEngine.query(
      Object.assign(
        {},
        this.params,
        {
          offset: this.offset,
        })
    )
      .pipe(tap(result => {
        if (result.items.length === this.params.take) {
          this.hasMoreItems = true;
        }
      }));
  }

  load() {
    return this.performSearch();
  }

  loadMore(): Observable<IQueueResponse<T> | null> {
    if (this.hasMoreItems) {
      const offset = this.offset + this.params.take;

      return this.performSearch(offset);
    }

    return of(null);
  }
}


@Injectable()
export class NetworkService<T extends IUserNetworkEntity>  {
  requestsProvider: ISearchEngine<T>;
  constructor(@Inject('networkServiceConfig')
              @Optional()
              private readonly config: INetworkServiceConfig,
              private ngbModalService: NgbModal
  ) {
    if (!this.config) {
      this.config = {
        take: 10
      };
    }
  }

  init(searchEngine: ISearchEngine<T>) {
    this.requestsProvider = searchEngine;
  }

  createSearchRequest(params: any): NetworkServiceResponse<T> {
    return new NetworkServiceResponse(
      this.requestsProvider,
      Object.assign({}, this.config, params)
    );
  }

  toggleLike(activity: T): Observable<null> {
    const observable = activity.likes.liked ?
      this.requestsProvider.unlike(activity, true) :
      this.requestsProvider.like(activity, true);

    activity.likes.count += activity.likes.liked ? -1 : 1;
    activity.likes.liked = !activity.likes.liked;

    return observable.pipe(catchError((err) => {
      activity.likes.count += activity.likes.liked ? -1 : 1;
      activity.likes.liked = !activity.likes.liked;

      return throwError(() => err);
    }));
  }

  showEditThreadDialog(activity:IUserNetworkActivity): Promise<IUserNetworkActivity> {
    const modalReference: NgbModalRef = this.ngbModalService.open(EditThreadModalComponent, {
      backdrop: 'static',
      animation: true,
      size: 'lg'
    });

    (<EditThreadModalComponent>modalReference.componentInstance).activity = Object.assign({}, activity);

    return modalReference.result;
  }

  editThreadReplyDialog(activity:IUserNetworkEntity): Observable<IUserNetworkEntity> {
    const modalReference: NgbModalRef = this.ngbModalService.open(EditThreadReplyModalComponent, {
      backdrop: 'static',
      animation: true
    });

    (<EditThreadReplyModalComponent>modalReference.componentInstance).activity = Object.assign({}, activity);

    return modalReference.closed;
  }
}
