import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { ElmsUtils } from 'core/utils/elms-utils';
import { IHelpText } from 'modules/help-texts/models/help-text.model';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';


export enum HelpTextEndpoints {
  GET = '/a/help_texts/:helpTextId',
  PUT = '/a/help_texts/:helpTextId/',
  POST = '/a/help_texts/',
}

export interface IHelpTextQuery{
  helpTextId?: number ;
  siteSettingId? :number;
}

@Injectable()
export class HelpTextService {
  private defaultProps: Partial<IHelpText> = {
    active: true,
  };

  constructor(private http: HttpClient) { }

  public get(query?: IHelpTextQuery, throwHttpErrors = false): Observable<IHelpText> {
    let params = new HttpParams();

    if (query?.siteSettingId) {
      params = params.set('siteSettingId', query?.siteSettingId);
    }

    return this.http.get<IHelpText>(
      ElmsUtils.formatUrl(HelpTextEndpoints.GET, { helpTextId: query?.helpTextId }),
      { params, context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    ).pipe(
      map(resource => Object.assign({}, this.defaultProps, resource))
    );
  }

  public post(payload: Partial<IHelpText>): Observable<IHelpText> {
    return this.http.post<IHelpText>( HelpTextEndpoints.POST, payload);
  }

  public update(helpTextId: number, payload: Partial<IHelpText>): Observable<IHelpText> {
    return this.http.put<IHelpText>(
      ElmsUtils.formatUrl(HelpTextEndpoints.PUT, { helpTextId }),
      payload
    );
  }

  public newHelpText(siteSettingId?: number): Partial<IHelpText> {
    return {
      ...this.defaultProps,
      ...({ siteSettingId })
    };
  }
}
