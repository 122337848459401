import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CurrentUserService } from 'ajs/modules/app/current-user.service';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';
import { ElmsUtils } from 'core/utils';
import _ from 'lodash';
import {
  ActivityOrigin,
  IUserNetworkActivity,
  IUserNetworkEntity,
} from 'modules/network/models/user-network-activity.model';
import { map, Observable } from 'rxjs';


export enum NetworkActivityPaths {
  QUERY = '/a/:activityOrigin/user_network/activities/',
  GET = '/a/:activityOrigin/user_network/activities/:activityId/',
  SAVE = '/a/:activityOrigin/user_network/activities/',
  UPDATE = '/a/:activityOrigin/user_network/activities/:activityId/',
  LIKE = '/a/:activityOrigin/user_network/activities/:activityId/likes/',
  UNLIKE = '/a/:activityOrigin/user_network/activities/:activityId/likes/',
  GET_COMMENTS = '/a/:activityOrigin/user_network/activities/:activityId/comments/',
  ADD_COMMENT = '/a/:activityOrigin/user_network/activities/:activityId/comments/'
}


export interface IQueueResponse<T> {
  count: number;
  items: T[];
}

export type IUserNetworkActivityQuery = {
  activityOrigin: ActivityOrigin,
  filter: {[key: string]: string},
  offset: number,
  take: number,
  userId?: number | string,
  query?: string,
};


@Injectable()
export class UserNetworkActivityService {
  constructor(
    private http: HttpClient,
    private currentUser: CurrentUserService
  ) {  }

  public query(
    params: IUserNetworkActivityQuery
  ): Observable<IQueueResponse<IUserNetworkActivity>> {
    const url = ElmsUtils.formatUrl(NetworkActivityPaths.QUERY, {
      activityOrigin: params.activityOrigin
    });

    let queryParams = new HttpParams().appendAll(_.omit(params, ['activityOrigin', 'filter', 'query']));

    if (params.filter) {
      queryParams = queryParams.set('filter', JSON.stringify(params.filter));
    }

    if (params.query) {
      queryParams = queryParams.set('query', params.query);
    }

    return this.http.get<IQueueResponse<IUserNetworkActivity>>(url, { params: queryParams })
      .pipe(
        map(activities => {
          activities.items.forEach(activity => {
            activity.comments.items.forEach(comment => {
              comment.activityOrigin = activity.activityOrigin;
              comment.date = new Date(comment.date);
              comment.updated = new Date(comment.updated);
            });

            activity.date = new Date(activity.date);
            activity.updated = new Date(activity.updated);
          });

          return activities;
        })
      );
  }

  public save(payload: Partial<IUserNetworkActivity>, throwHttpErrors = false): Observable<IUserNetworkActivity> {
    return this.http.post<IUserNetworkActivity>(
      ElmsUtils.formatUrl(NetworkActivityPaths.SAVE, { activityOrigin: payload.activityOrigin }),
      payload,
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    );
  }

  public update(payload: Partial<IUserNetworkActivity>, throwHttpErrors = false): Observable<IUserNetworkActivity> {
    return this.http.put<IUserNetworkActivity>(
      ElmsUtils.formatUrl(NetworkActivityPaths.UPDATE, {
        activityId: payload.id,
        activityOrigin: payload.activityOrigin
      }),
      payload,
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    );
  }

  public like(activity: IUserNetworkActivity, throwHttpErrors = false): Observable<null> {
    return this.http.post<null>(
      ElmsUtils.formatUrl(
        NetworkActivityPaths.LIKE, { activityId: activity.id, activityOrigin: activity.activityOrigin }
      ),
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    );
  }

  public unlike(activity: IUserNetworkActivity, throwHttpErrors = false): Observable<null> {
    return this.http.delete<null>(
      ElmsUtils.formatUrl(
        NetworkActivityPaths.UNLIKE, { activityId: activity.id, activityOrigin: activity.activityOrigin }
      ),
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    );
  }

  public addComment(
    activityId: number, payload: IUserNetworkEntity, throwHttpErrors = false
  ): Observable<IUserNetworkActivity> {
    return this.http.post<IUserNetworkActivity>(
      ElmsUtils.formatUrl(NetworkActivityPaths.ADD_COMMENT, { activityId, activityOrigin: payload.activityOrigin }),
      payload,
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    );
  }

  public getComments(activityId: number, activityOrigin: ActivityOrigin): Observable<IUserNetworkEntity[]> {
    return this.http.get<IUserNetworkEntity[]>(
      ElmsUtils.formatUrl(NetworkActivityPaths.GET_COMMENTS, { activityId, activityOrigin }),
    );
  }

  public newPost(attrs?: Partial<IUserNetworkActivity>): IUserNetworkActivity {
    const user = this.currentUser.get();
    const currentDate = new Date();

    return Object.assign({}, {
      user: {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName
      },
      url: '',
      text: '',
      activityOrigin: 'user',
      activityType: 'post',
      likes: { count: 0, liked: false },
      comments: [],
      userId: user.id,
      date: currentDate,
      updated: currentDate
    },
    attrs || {}) as IUserNetworkActivity;
  }
}
