import { NgHybridStateDeclaration } from '@uirouter/angular-hybrid';
import { CorporateLoginComponent } from 'modules/login/components/corporate-login.component';
import { LoginFormComponent } from 'modules/login/components/login-form.component';
import { StateService, Transition } from '@uirouter/core';
import { SiteSettingsService } from 'core/site-settings/services/site-settings.service';
import { ForgotPasswordComponent } from 'modules/login/components/forgot-password.component';
import { PasswordAssistanceComponent } from 'modules/login/components/password-assistance.component';
import { CurrentUserService } from 'core/authorization';
import { UserVerificationService } from 'modules/login/services/user-verification.service';
import {
  EmailVerificationRequiredAlertDialogComponent
} from 'modules/login/components/email-verification-required-alert.component';
import { lastValueFrom } from 'rxjs';


export const loginRoutingModule: NgHybridStateDeclaration[] = [
  {
    name: 'prompt.corporateLogin',
    url: '/corporate',
    params: {
      inplace: null,
      email: null
    },
    component: CorporateLoginComponent,
    data: {
      label: 'Corporate Sign in',
      availableFor: ['anonymous'],
    }
  },
  {
    name: 'prompt.login',
    url: '^/signin?inplace&email&next',
    params: {
      inplace: null,
      email: null,
      next: null,
    },
    component: LoginFormComponent,
    data: {
      availableFor: ['anonymous'],
      label: 'Sign In',
    },
    resolve: [
      {
        provide: 'signin',
        deps: [Transition],
        useFactory: (transition: Transition) => {
          return transition.params().signin;
        }
      },
      {
        provide: 'email',
        deps: [Transition],
        useFactory: (transition: Transition) => {
          return transition.params().email;
        }
      },
      {
        provide: 'next',
        deps: [Transition],
        useFactory: (transition: Transition) => {
          return transition.params().next;
        }
      },
      {
        provide: 'currentSiteSetting',
        deps: [SiteSettingsService],
        useFactory: (siteSettingsService: SiteSettingsService) => {
          return siteSettingsService.getCurrent();
        }
      }
    ]
  },
  {
    name: 'prompt.forgotPassword',
    url: '/forgotPassword?email&accountLocked',
    params: {
      email: null,
      accountLocked: null
    },
    data: {
      label: 'Forgot password',
      availableFor: ['anonymous', 'regularUser', 'admin']
    },
    component: ForgotPasswordComponent,
    resolve: [
      {
        provide: 'email',
        deps: [Transition],
        useFactory: (transition: Transition) => {
          return transition.params().email;
        }
      },
      {
        provide: 'accountLocked',
        deps: [Transition],
        useFactory: (transition: Transition) => {
          return transition.params().accountLocked;
        }
      },
      {
        provide: 'lockedTypeId',
        deps: [Transition],
        useFactory: (transition: Transition) => {
          return transition.params().lockedTypeId;
        }
      },
      {
        provide: 'lockedDescription',
        deps: [Transition],
        useFactory: (transition: Transition) => {
          return transition.params().lockedDescription;
        }
      }
    ]
  },
  {
    name: 'prompt.passwordAssistance',
    url: '/passwordAssistance?token',
    params: {
      token: null
    },
    data: {
      label: 'Password Assistance',
      availableFor: ['anonymous', 'regularUser', 'admin'],
    },
    component: PasswordAssistanceComponent,
    resolve: [
      {
        provide: 'token',
        deps: [Transition],
        useFactory: (transition: Transition) => {
          return transition.params().token;
        }
      }
    ]
  },
  {
    name: 'prompt.emailVerificationRequired',
    url: '/email-verification-required',
    data: {
      label: 'Email verification required',
      availableFor: ['regularUser', 'admin'],
    },
    component: EmailVerificationRequiredAlertDialogComponent,
    resolve: [
      {
        provide: 'verificationInfo',
        deps: [UserVerificationService, CurrentUserService],
        useFactory: (userVerificationService: UserVerificationService, currentUser: CurrentUserService) => {
          const user = currentUser.get();

          return lastValueFrom(userVerificationService.getVerificationInfo(user.id));
        }
      },
      {
        provide: 'user',
        deps: [CurrentUserService, Transition, StateService],
        useFactory: (currentUser: CurrentUserService) => {
          return currentUser.get();
        }
      }
    ]
  }
];

