import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ElmsUtils } from 'core/utils';
import { IVerificationResponse } from 'modules/login/models/verification.models';


export enum VerificationEndpoints {
  VERIFICATION_INFO = '/a/user/:userId/email_verification/'
}

@Injectable()
export class UserVerificationService {
  constructor(
    private http: HttpClient
  ) {}

  getVerificationInfo(userId: number): Observable<IVerificationResponse> {
    return this.http.get<IVerificationResponse>(ElmsUtils.formatUrl(VerificationEndpoints.VERIFICATION_INFO,
      { userId: userId }));
  }
}
