import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { IGroup, IGroupQueryResponse, IGroupsQuery, IGroupState } from 'modules/group/models/group.model';
import { CACHE_NAME_TOKEN, CACHE_TTL_TOKEN, TTL } from 'core/http-interceptors/http-caching-interceptor';
import { ElmsUtils } from 'core/utils/elms-utils';
import { map, Observable, of } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';


export enum GroupsRequestPaths {
  GET = '/a/groups/:groupId/',
  PUT = '/a/groups/:groupId/',
  POST = '/a/groups/',
  QUERY = '/a/groups/',
  STATES = '/a/user/states/',
}

@Injectable()
export class GroupService {
  protected queryParams?: IGroupsQuery;
  protected defaultProps: Partial<IGroup> = {
    active: true,
    visible: true,
    globalId: uuidV4(),
    promptPortalPolicy: true
  };

  constructor(protected http: HttpClient) { }

  public query(query?: IGroupsQuery): Observable<IGroupQueryResponse> {
    let params = new HttpParams();

    this.queryParams = query;

    if (query?.query) {
      params = params.set('query', JSON.stringify(query.query));
    }

    return this.http.get<IGroupQueryResponse>(
      GroupsRequestPaths.QUERY,
      {
        params,
        context: new HttpContext()
          .set(CACHE_NAME_TOKEN, 'groupCache')
          .set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE)
      }
    );
  }

  public loadMore(count: number): Observable<IGroupQueryResponse | null> {
    if (this.queryParams) {
      return this.query({
        query: {
          ...this.queryParams.query,
          start: this.queryParams.query.start + count, count: count
        }
      });
    }

    return of(null);
  }

  public get(groupId: number, permitted?: string): Observable<IGroup> {
    let params = new HttpParams();

    if (permitted?.length) {
      params = params.set('permitted_for', permitted);
    }

    return this.http.get<IGroup>(
      ElmsUtils.formatUrl(GroupsRequestPaths.GET, { groupId }),
      { params }
    ).pipe(map(resource => Object.assign({}, this.defaultProps, resource)));
  }

  public getStates(): Observable<IGroupState[]> {
    return this.http.get<IGroupState[]>(GroupsRequestPaths.STATES, {
      context: new HttpContext()
        .set(CACHE_NAME_TOKEN, 'groupStates')
        .set(CACHE_TTL_TOKEN, TTL.NEVER_EXPIRE)
    });
  }
}
