import { Component, OnInit } from '@angular/core';
import { SamlService } from 'modules/integrations/services/saml.service';
import { ISsoClient } from 'modules/integrations/models/saml-sso.model';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'app-saml-sso-client-list',
  templateUrl: './saml-sso-client-list.component.html'
})

export class SamlSsoClientListComponent implements OnInit {
  public ssoClients: ISsoClient[];
  public loading: boolean | null = null;
  public appClient: string;

  constructor(
    private samlService: SamlService,
  ) {
  }

  ngOnInit(): void {
    this.loading = true;

    this.samlService.getSsoConfig()
      .pipe(finalize(() => {
        this.loading = null;
      }))
      .subscribe(config => {
        this.ssoClients = config;
      });
  }
}
