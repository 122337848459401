import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ElmsUtils } from 'core/utils';
import { ILearningSeries, ILearningSeriesStats, ILearningSeriesStatus } from '../models/learning-series.models';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors';


export enum LearningSeriesEndpoints {
  GET = '/a/learning_series/:learningSeriesId/',
  STAT = '/a/learning_series/:learningSeriesId/stat/',
  PROGRESS = '/a/user/:userId/learning_series/progress/',
  CERTIFICATE = '/a/learning_series/:learningSeriesId/users/:userId/certificate-content/'
}

@Injectable()
export class LearningSeriesService {
  constructor(private http: HttpClient) { }

  public get(learningSeriesId: number, throwHttpErrors = false): Observable<ILearningSeries> {
    return this.http.get<ILearningSeries>(
      ElmsUtils.formatUrl(LearningSeriesEndpoints.GET, { learningSeriesId }),
      { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    );
  }

  public downloadProofOfCompletion(learningSeriesId: number, userId: number): void {
    window.open(ElmsUtils.formatUrl(LearningSeriesEndpoints.CERTIFICATE, { learningSeriesId, userId }));
  }

  public userProgress(userId: number, learningSeriesIds: number[]): Observable<ILearningSeriesStatus[]> {
    return this.http.put<ILearningSeriesStatus[]>(
      ElmsUtils.formatUrl(LearningSeriesEndpoints.PROGRESS, { userId }),
      { learning_series_ids: learningSeriesIds }
    );
  }

  public stat(learningSeriesId: number): Observable<ILearningSeriesStats> {
    return this.http.get<ILearningSeriesStats>(
      ElmsUtils.formatUrl(LearningSeriesEndpoints.STAT, { learningSeriesId })
    );
  }
}
