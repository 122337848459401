import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { IUser } from 'modules/user/models/user.model';
import { PasswordPoliciesService } from 'modules/password/services/password-policies.service';
import { catchError, debounceTime, map, of, Subject } from 'rxjs';
import { IValidationResult } from 'modules/password/models/password-policies.model';


@Component({
  selector: 'password-input',
  templateUrl: './password-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    },
  ],
})
export class PasswordInputComponent implements OnInit {
    @Input() user: Partial<IUser>;
    @Input() submitted: boolean;
    @Input() showOnlyFailedPolicies = false;

    rules: any[];
    validatedRules: IValidationResult;
    value = '';
    passwordDirty = false;

    private passwordChange$ = new Subject<string>();

    constructor(
        private passwordPoliciesService: PasswordPoliciesService
    ) {}


    onRulesChange(updatedRules: any[]) {
      this.rules = updatedRules;  // Update the rules in the parent component
    }

    ngOnInit() {
      this.passwordChange$
        .pipe(debounceTime(500))
        .subscribe(() => {
          if (this.rules && this.user.password) {
            this.passwordChanged();
          }
        });
    }

    // Called when the ngModel is updated
    onPasswordChange(password: string): void {
      this.value = password;
      this.passwordDirty = true; // Update the dirty state
      this.onChange(password);
      this.onValidatorChange();
      this.passwordChange$.next(password);
    }

    passwordChanged() {
      const validatedRules = this.passwordPoliciesService.preValidatePasswordRules(this.rules, this.user.password,
        this.user);

      return this.passwordPoliciesService.checkPasswordRules(this.user.password, this.user, true)
        .pipe(
          map((rules) => {
            // Merge failed and completed rules using native Set to remove duplicates
            validatedRules.failedRules = [...new Set([...validatedRules.failedRules, ...rules.failedRules])];
            validatedRules.completedRules = [...new Set([...validatedRules.completedRules,
              ...rules.completedRules])];

            this.validatedRules = validatedRules;
            this.onValidatorChange();

            // Return validation result based on the number of failed rules
            if (this.validatedRules.failedRules.length) {
              throw new Error('Validation failed');  // Trigger error flow
            }

            return of(null);
          }),
          catchError(() => of(null))
        ).subscribe();
    }

    // ControlValueAccessor methods
    writeValue(value: any): void {
      this.value = value;
      // Optionally update the view
    }

    registerOnChange(fn: any): void {
      this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
      this.onTouched = fn;
    }

    // Validator methods
    registerOnValidatorChange(fn: () => void): void {
      this.onValidatorChange = fn;
    }

    validate(): ValidationErrors | null {
      const errors: ValidationErrors = {};

      if (!this.value || this.value.trim() === '') {
        errors['required'] = true;
      }

      if (this.user?.oldPassword && this.user.oldPassword === this.value) {
        errors['sameAsOld'] = true;
      }

      if (this.validatedRules?.failedRules?.length > 0) {
        errors['passwordPolicy'] = true;
      }

      return Object.keys(errors).length ? errors : null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
    private onChange = (_: any) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private onTouched = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private onValidatorChange = () => {};
}
