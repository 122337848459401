import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CurrentUserService } from 'core/authorization';
import { SiteSettingsService } from 'core/site-settings/services/site-settings.service';
import { GlobalConfig } from 'app/core/environment';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';
import { ErrorHandlerService } from 'core/services';
import { ElmsAuthService } from 'ajs/modules/app/authorization/elms-auth-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { StateService } from '@uirouter/core';
import { ISiteSettings } from 'core/site-settings/models/site-settings.model';
import {
  EmailVerificationImportantModalComponent
} from 'modules/login/components/modals/email-verification-important-modal.component';
import { UserEmailOptInService } from 'modules/user/services/user-email-opt-in.service';
import { catchError, EMPTY, finalize, map, switchMap } from 'rxjs';
import { UserService } from 'modules/user/services/user.service';
import { IVerificationResponse } from 'modules/login/models/verification.models';
import { IUser } from 'modules/user/models/user.model';
import { UserVerificationService } from 'modules/login/services/user-verification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorsService } from 'core/services/errors.service';


@Component({
  selector: 'email-verification-required-alert-dialog',
  templateUrl: './email-verification-required-alert.component.html'
})
export class EmailVerificationRequiredAlertDialogComponent implements OnInit {
  @Input() verificationInfo: IVerificationResponse;
  @Input() user: IUser;

  @ViewChild('emailVerificationRequiredForm', { static: true }) emailVerificationRequiredForm: NgForm;

  signInWording = this.globalConfig.signInWording;
  steps: string[] = [];
  processing = false;
  step = '';
  userEmailDraft: string;
  loading = false;
  submitted = false;
  uniqueEmailError = false;
  updating = false;
  updateError = false;
  duplicateOptions: number;
  myAccountEmail = false;
  otherAccountsNotAvailable = false;
  currentSiteSetting: ISiteSettings;


  constructor(
    private currentUser: CurrentUserService,
    private siteSettingsService: SiteSettingsService,
    private globalConfig: GlobalConfig,
    private notificationService: NotificationService,
    private userEmailOptInService: UserEmailOptInService,
    private errorHandlerService: ErrorHandlerService,
    private elmsAuthService: ElmsAuthService,
    private modalService: NgbModal,
    private stateService: StateService,
    private userService: UserService,
    private userVerificationService: UserVerificationService
  ) {}

  async ngOnInit() {
    this.currentSiteSetting = await this.siteSettingsService.getCurrent();
    this.userEmailDraft = null;
    this.initStep();
  }

  showVerificationImportance() {
    this.modalService.open(EmailVerificationImportantModalComponent);
  }

  reInitVerification() {
    this.loading = false;
    this.userVerificationService.getVerificationInfo(this.user.id).pipe().subscribe((info: any) => {
      this.verificationInfo = info;
      this.initStep();
    });
  }

  initStep() {
    if (this.verificationInfo && !this.verificationInfo.uniqEmail) {
      if (!this.verificationInfo.selfCreated) {
        this.step = 'changeEmail';
      } else {
        if (this.verificationInfo.verifiedAccountsExists) {
          this.step = 'duplicatesWithVerified';
        } else {
          this.step = 'duplicatesNotVerified';
        }
      }
    } else {
      this.step = 'confirmation';
    }
  }

  nextStep(step: string) {
    this.steps.push(this.step);
    this.step = step;
  }

  prevStep() {
    this.step = this.steps.pop() || 'confirmation';
  }

  duplicateContinue() {
    switch (this.duplicateOptions) {
      case 1:
        this.nextStep('duplicateConfirmation');
        break;
      case 2:
        this.nextStep('changeEmail');
        break;
      case 3:
        this.elmsAuthService.logout().then(() => {
          this.currentUser.loadData().then(() => {
            this.stateService.go('prompt.login');
          });
        });
        break;
      case 4:
        this.elmsAuthService.logout().then(() => {
          this.currentUser.loadData().then(() => {
            this.stateService.go('prompt.forgotPassword');
          });
        });
        break;
      default:
        break;
    }
  }

  sendVerificationEmail(step?: string) {
    this.processing = true;

    this.userEmailOptInService.resendAction(true).
      pipe(
        map(() => {
          this.notificationService.info('Verification email sent successfully.', 2000);
        }),
        catchError(e => this.handleError(e)), finalize(() => {
          this.processing = false;

          if (step) {
            this.nextStep(step);
          }
        })
      ).subscribe();
  }

  updateEmail() {
    this.submitted = true;

    if (this.emailVerificationRequiredForm.valid && this.userEmailDraft && this.userEmailDraft !== this.user.email) {
      this.processing = true;
      this.userService.checkEmail(this.user, this.userEmailDraft, this.user.active, true)
        .pipe(
          switchMap((unique: boolean) => {
            this.uniqueEmailError = !unique;

            if (unique) {
              this.updating = true;

              return this.userService.update({
                id: this.user.id,
                email: this.userEmailDraft,
                activeOnly: _.get(this.globalConfig, 'settings.user.checkDuplicatesForActiveOnly', null)
              });
            }
          }),
          switchMap((user) => {
            Object.assign(this.user, user);
            this.currentUser.set(this.user);
            this.notificationService.info('Email updated successfully', 2000);

            return this.userEmailOptInService.resendAction(true);
          }),
          catchError((e) => this.handleError(e)),
          finalize(() => {
            if (!this.uniqueEmailError && !this.updateError) {
              this.nextStep('summary');
            }

            this.updating = false;
            this.processing = false;
            this.submitted = false;
            this.updateError = false;
          })).subscribe();
    }
  }

  get contactsLink(): string {
    return this.stateService.href('main.contacts') || this.stateService.href('main.support') ||
      `mailto:${this.currentSiteSetting.supportEmail}`;
  }

  get logoutLink(): string {
    return this.stateService.href('logout');
  }

  private handleError(reason: HttpErrorResponse) {
    const message = typeof reason.error === 'string' ? reason.error : ErrorsService.defaultMessage;

    this.updateError = true;
    this.notificationService.error(message, 5e3);

    return EMPTY;
  }
}
