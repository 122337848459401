<div class="modal-header">
  <button type="button" class="close" aria-label="Cancel" title="Cancel" (click)="cancelModal()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">Reset password</h4>
</div>

<div class="modal-body">
  <p>An email will be sent to the user with instructions for resetting the password.</p>
  <p>User's current password will stop working if you proceed.</p>

  <div class="alert alert-sm alert-danger" *ngIf="error">{{ error }}</div>
</div>

<div class="modal-footer">
  <button class="btn btn-warning" (click)="cancelModal()" [disabled]="resetPasswordPromise" title="Cancel">Cancel</button>
  <button class="btn btn-success" (click)="resetPassword()" [disabled]="resetPasswordPromise" title="Submit">Submit</button>
</div>
