import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CorporateLoginComponent } from 'modules/login/components/corporate-login.component';
import { NavigationModule } from 'modules/navigation/navigation.module';
import { FormsModule } from '@angular/forms';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { loginRoutingModule } from 'modules/login/login-routing.module';
import { LoginFormComponent } from 'modules/login/components/login-form.component';
import { RouterOutlet } from '@angular/router';
import { PasswordLoginComponent } from 'modules/login/components/password-login.component';
import { ForgotPasswordComponent } from 'modules/login/components/forgot-password.component';
import { LmsComponentsModule } from 'components/kmi.lms.components.module';
import { UserPasswordService } from 'modules/password/services/user-password.service';
import { PasswordAssistanceComponent } from 'modules/login/components/password-assistance.component';
import { PasswordModule } from 'modules/password/password.module';
import {
  EmailVerificationRequiredAlertDialogComponent
} from 'modules/login/components/email-verification-required-alert.component';
import {
  EmailVerificationImportantModalComponent
} from 'modules/login/components/modals/email-verification-important-modal.component';
import { UserVerificationService } from 'modules/login/services/user-verification.service';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NavigationModule,
    FormsModule,
    UIRouterUpgradeModule.forChild({ states: loginRoutingModule }),
    RouterOutlet,
    LmsComponentsModule,
    PasswordModule
  ],
  providers: [
    UserPasswordService,
    UserVerificationService
  ],
  declarations: [
    CorporateLoginComponent,
    LoginFormComponent,
    PasswordLoginComponent,
    ForgotPasswordComponent,
    PasswordAssistanceComponent,
    EmailVerificationRequiredAlertDialogComponent,
    EmailVerificationImportantModalComponent
  ],
  exports: [
    PasswordLoginComponent
  ]
})
export class LoginModule {}
