<div class="modal-header">
  <button [disabled]="savePromise" type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4 class="modal-title">
    <span *ngIf="!activity.id">Reply</span>
    <span *ngIf="activity.id">Edit reply</span>
  </h4>
</div>

<div class="modal-body overflow-auto edit-post-modal">
  <form #threadForm="ngForm">
    <label for="threadDraft" class="sr-only">Reply</label>
    <textarea class="form-control required resize-vertical"
              name="commentsText" rows="3"
              placeholder="Something goes here"
              id="threadDraft" ngbAutofocus
              noWhiteSpace
              [(ngModel)]="activity.text"></textarea>
  </form>
</div>

<div class="modal-footer">
  <fieldset class="no-margin-bottom">
    <button type="button" title="Cancel"
            class="btn btn-warning" (click)="cancel()">Cancel
    </button>

    <button type="button" [ladda]="savePromise"  [disabled]="!activity.text?.trim().length || savePromise"
            [title]="activity.id ? 'Edit reply' : 'Reply to thread' "
            class="btn btn-success" (click)="submit()"> {{activity.id ? 'Edit reply' : 'Reply to thread' }}</button>
  </fieldset>
</div>
