import { Component, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, EMPTY, finalize, tap, Unsubscribable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { IUserNetworkEntity } from 'modules/network/models/user-network-activity.model';
import { UserNetworkActivityService } from 'modules/network/services/user-network-activity.service';


@Component({
  selector: 'edit-thread-modal',
  templateUrl: './edit-thread-modal.component.html'
})
export class EditThreadModalComponent implements OnDestroy {
  savePromise = false;
  activity: IUserNetworkEntity;
  error: string;

  private requestSubscriber?: Unsubscribable;

  constructor(
    private activeModal: NgbActiveModal,
    private userNetworkActivityService: UserNetworkActivityService,
  ) {}

  ngOnDestroy(): void {
    this.clearRequestSubscriber();
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

  submit(): void {
    this.savePromise = true;
    const draft = Object.assign({}, this.activity, { text: this.activity.text.trim() });

    const observable = this.activity.id ?
      this.userNetworkActivityService.update(draft, true) :
      this.userNetworkActivityService.save(draft, true);

    this.requestSubscriber = observable
      .pipe(
        tap((result) => this.activeModal.close(Object.assign(this.activity, result))),
        catchError((e: HttpErrorResponse) => this.handleError(e)),
        finalize(() => this.clearRequestSubscriber())
      )
      .subscribe();
  }

  private handleError(e: HttpErrorResponse) {
    this.error = e.error;

    return EMPTY;
  }

  private clearRequestSubscriber(): void {
    this.savePromise = false;

    if (this.requestSubscriber) {
      this.requestSubscriber.unsubscribe();
      delete this.requestSubscriber;
    }
  }
}
