import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalConfig } from 'core/environment/global-config.service.ajs-upgraded-provider';
import { IMenuItem } from 'modules/navigation/models/navigation.model';


@Component({
  selector: 'navigation-menu',
  templateUrl: './navigation-menu.component.html'
})
export class NavigationMenuComponent implements OnInit {
  static readonly selector = 'navigationMenu';

  @Input() menuItems: IMenuItem[];
  @Input() visibleItemsCount?: number;
  @Output() clickHandler = new EventEmitter<void>();

  mainMenu: IMenuItem[] = [];
  extMenu: IMenuItem[] = [];
  extenderTitle: string = this.globalConfig.mainMenu.extenderTitle;
  menuOpened = false;


  constructor(
    private globalConfig: GlobalConfig,
  ) { }

  ngOnInit() {
    if (this.visibleItemsCount) {
      if (this.menuItems.length <= this.visibleItemsCount) {
        this.mainMenu = this.menuItems;
      } else {
        this.mainMenu = this.menuItems.slice(0, this.visibleItemsCount - 1);
        this.extMenu = this.menuItems.slice(this.visibleItemsCount - 1);
      }
    } else {
      this.mainMenu = this.menuItems;
    }
  }

  onMenuClick() {
    this.clickHandler.emit();
  }
}
