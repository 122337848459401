import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PASS_HTTP_ERRORS_TOKEN } from 'core/http-interceptors/http-request-error-handler.interceptor';
import { ILinkAccountData, IResponseLinkResult } from 'modules/integrations/models/open-id-connect.models';
import { ISsoLoginCredentials } from 'modules/integrations/models/saml-sso.model';
import { Observable } from 'rxjs';


enum OpenIdConnectEndpoints {
  linkAccount = '/a/integration/open_id_connect/link_account/'
}


@Injectable()
export class OpenIdConnectService {
  constructor(private http: HttpClient) {}

  linkAccount(params?: ISsoLoginCredentials, throwHttpErrors = false): Observable<IResponseLinkResult> {
    return this.http.post<IResponseLinkResult>(
      OpenIdConnectEndpoints.linkAccount,
      params, { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    );
  }

  getLinkAccountData(throwHttpErrors = false): Observable<ILinkAccountData> {
    return this.http.get<ILinkAccountData>(
      OpenIdConnectEndpoints.linkAccount, { context: new HttpContext().set(PASS_HTTP_ERRORS_TOKEN, throwHttpErrors) }
    );
  }
}
